import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=312ac9aa&scoped=true&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"
import style0 from "./Login.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Login.vue?vue&type=style&index=1&lang=css&"
import style2 from "./Login.vue?vue&type=style&index=2&id=312ac9aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312ac9aa",
  null
  
)

/* custom blocks */
import block0 from "./Login.vue?vue&type=custom&index=0&blockType=b-link&class=brand-logo"
if (typeof block0 === 'function') block0(component)
import block1 from "./Login.vue?vue&type=custom&index=1&blockType=b-col&lg=4&class=d-flex%20align-items-center%20auth-bg%20px-2%20p-lg-5"
if (typeof block1 === 'function') block1(component)
import block2 from "./Login.vue?vue&type=custom&index=2&blockType=b-col&lg=8&style=padding%3A%200px"
if (typeof block2 === 'function') block2(component)

export default component.exports