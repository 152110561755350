<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login and overlay image combined in one column -->
      <b-col lg="8" class="d-flex align-items-center auth-bg px-2 p-lg-5 login-overlay-container">
        <!-- Overlay Image -->
        <div class="login-overlay-image">
          <img
            src="@/assets/images/logo/vertical-logo-wholewhite.svg"
            alt="logo"
          />
        </div>
        <!-- /Overlay Image -->

        <!-- Login Form -->
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto login-form">
          <b-card-title class="mb-1 font-weight-bold login-heading-text" title-tag="h2">
              Welcome to PurpleTrac!
          </b-card-title>
          <!-- Login form here... -->
            <validation-observer ref="loginForm" #default="{ invalid }">
                <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent="login"
                >
                    <!-- email -->
                    <b-form-group label="Email" label-for="login-email">
                        <validation-provider
                            #default="{ errors }"
                            name="Email"
                            vid="email"
                            rules="required|email"
                        >
                            <b-form-input
                                id="login-email"
                                v-model="userEmail"
                                :state="
                                    errors.length > 0 ? false : null
                                "
                                name="login-email"
                                placeholder="john@example.com"
                            />
                            <small class="text-danger">{{
                                errors[0]
                            }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                        <!-- <div class="d-flex justify-content-between">
                            <label for="login-password">Password</label>
                            <b-link :to="{ name: 'auth-register' }">
                                <small>Sends email for reset</small>
                            </b-link>
                        </div> -->
                        <validation-provider
                            #default="{ errors }"
                            name="Password"
                            vid="password"
                            rules="required"
                        >
                            <b-input-group
                                class="input-group-merge"
                                :class="
                                    errors.length > 0
                                        ? 'is-invalid'
                                        : null
                                "
                            >
                                <b-form-input
                                    id="login-password"
                                    v-model="password"
                                    :state="
                                        errors.length > 0 ? false : null
                                    "
                                    class="form-control-merge"
                                    :type="passwordFieldType"
                                    name="login-password"
                                    placeholder="Password"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        class="cursor-pointer"
                                        :icon="passwordToggleIcon"
                                        @click="
                                            togglePasswordVisibility
                                        "
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{
                                errors[0]
                            }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- checkbox -->
                    <b-form-group>
                        <b-form-checkbox
                            id="remember-me"
                            v-model="status"
                            name="checkbox-1"
                        >
                            Remember Me
                        </b-form-checkbox>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                        type="submit"
                        variant="primary"
                        block
                        :disabled="invalid"
                    >
                        Sign in
                    </b-button>
                </b-form>
            </validation-observer>
            <b-card-text class="text-center mt-2">
                <span>New on our platform? </span>
                <b-link :to="{name:'auth-register'}">
                    <span>Create an account</span>
                </b-link>
            </b-card-text>
            <b-card-text class="text-center mt-2">
                <span>Forgot Password? </span>
                <b-link :to="{name:'auth-forgot-password'}">
                    <span>&nbsp;Click here</span>
                </b-link>
            </b-card-text>
          
        </b-col>
         
      </b-col>
      <!-- /Login and overlay image combined in one column -->

      <!-- Right Text or Image Placeholder (if you want to keep something here) -->
      <b-col lg="4" class="right-text-placeholder">
        <!-- Content here will be displayed on larger screens -->
      </b-col>
      <!-- /Right Text or Image Placeholder -->
    </b-row>
  </div>
</template>

<!--<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <!-- <vuexy-logo /> -->
                <img :src="logo" style="height: 45px; width: 37px" />
                <div class="business-metrics-text">
                    <h2
                        class="brand-text text-primary ml-1"
                        style="color: rgb(77, 20, 140)"
                    >
                        purpletrac
                        <p
                            
                            style="
                                font-size: 8px;
                                letter-spacing: 0.9px;
                                line-height: 10px;
                            "
                        >
                            TRACKING BUSINESS METRICS FOR FEDEX
                        </p>
                    </h2>
                </div>
            </b-link>
            <!-- /Brand logo-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title class="mb-1 font-weight-bold login-heading-text" title-tag="h2">
                        Welcome to PurpleTrac!
                    </b-card-title>
                    <!-- <b-card-text class="mb-2">
                    Please sign-in to your account and start the administration
                </b-card-text> -->

                    <!-- form -->
                    <validation-observer ref="loginForm" #default="{ invalid }">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="login"
                        >
                            <!-- email -->
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <!-- <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link :to="{ name: 'auth-register' }">
                                        <small>Sends email for reset</small>
                                    </b-link>
                                </div> -->
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="Password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Remember Me
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="invalid"
                            >
                                Sign in
                            </b-button>
                        </b-form>
                    </validation-observer>
                    <b-card-text class="text-center mt-2">
                        <span>New on our platform? </span>
                        <b-link :to="{name:'auth-register'}">
                            <span>Create an account</span>
                        </b-link>
                    </b-card-text>
                    <b-card-text class="text-center mt-2">
                    <span>Forgot Password? </span>
                    <b-link :to="{name:'auth-forgot-password'}">
                        <span>&nbsp;Click here</span>
                    </b-link>
                </b-card-text>
                </b-col>
            </b-col>
            <!-- /Login-->

            <!-- Left Text-->
            <b-col lg="8" style="padding: 0px">
                <div
                    class="login-image"
                    style="
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <img
                        src="@/assets/images/logo/vertical-logo-wholewhite.svg"
                        style="padding: 150px 200px"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->
        </b-row>
    </div>
</template>
-->
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Logo from "@/assets/images/logo/favicon.png";
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VBTooltip,
} from "bootstrap-vue";
//import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import axios from "axios";
import { serverUri } from "@/config";
//import { fetchUserData } from '@/auth/userData';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BAlert,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        Logo,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: "",
            password: "",
            userEmail: "",
            sideImg: require("@/assets/images/pages/login-v2.png"),

            // validation rules
            required,
            email,
            logo: Logo,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require("@/assets/images/pages/login-v2.png");
                return this.sideImg;
            }
            return this.sideImg;
        },
    },
    methods: {
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: "BellIcon",
                    text: text,
                    variant,
                },
            });
        },

       
        async login() {

            
                const isValid = await this.$refs.loginForm.validate();
                    if (isValid) {

                        try{

                            await store.dispatch("auth/login", {
                                email: this.userEmail,
                                password: this.password,
                            });

                            const userData = store.state.auth.user;
                   
                            if(userData){
                                
                                await this.$ability.update(userData.ability);
                                
                            }

                            await this.$router.push(getHomeRouteForLoggedInUser(userData.role));
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    position: "top-right",
                                    props: {
                                    title: `Welcome ${userData.full_name}`,
                                    icon: "CoffeeIcon",
                                    variant: "success",
                                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                    },
                                }
                            );
                            
                            
                            
                            
                        }catch(error){
                            console.log(error);

                            this.$refs.loginForm.setErrors(error.response);
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                title: `Error`,
                                icon: "XIcon",
                                variant: "danger",
                                text: `${error.response.msg}`,
                                },
                            });
                        };
                    }
        },
    },
}
                    
                    
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style>
.login-image {
    background-image: url("../../../assets/images/pages/login-v2.png");
    background: linear-gradient(0, 0, 0, 0.3);
}
@media only screen and (min-width:320px) and (max-width:640px)
{
    .login-heading-text {
        margin-top:50px !important;
    }
}
@media (max-width: 993px) {
    .business-metrics-text {
        display: none;
    }
}
</style>
<style scoped>
@media (max-width: 992px) {
  .brand-logo {
    display: none; /* Hide the logo on smaller screens */
  }
}

.login-overlay-container {
  position: relative;
  border-radius: 10px; /* Adjust border-radius as needed */
  overflow: hidden; /* Ensures the content respects the border-radius */
}

.login-overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/logo/vertical-logo-wholewhite.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Adjust as needed */
  z-index: 1; /* Ensure the image is under the form */
}

.login-form {
  position: relative;
  z-index: 2; /* Ensure the form is above the overlay image */
  background: rgba(255, 255, 255, 0.9); /* Slight transparency to see the image behind */
  border-radius: 10px; /* Match border-radius of the container */
  padding: 20px; /* Add some padding */
}
</style>